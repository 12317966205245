@import url("./assets/css/style.css");
@import url("./assets/css/bootstrap.min.css");
@import url("./assets/css/bootstrap-datepicker.css");
@import url("./assets/css/owl.carousel.min.css");
@import url("./assets/css/owl.theme.default.min.css");
@import url("./assets/css/flaticon.css");
@import url("./assets/css/aos.css");
@import url("./assets/css/style1.css");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Header */
.logo {
  height: 18vmin;
  /* height: 8rem; */
  pointer-events: none;
}

.floating-button {
  position: fixed;
  display: flex;
  text-align: end;
  right: 0;
  justify-content: end;
  align-items: end;
  flex-direction: column;
  z-index: 999999;
}

.floating-button a{
  margin-bottom: 0.5rem;
}


.callus{
  border-radius:10px !important;
  padding-left: 20px;
  margin-right: -6.3rem;
  transition: all 0.5s;
}
.callus:hover {
  margin-right: 0rem;
}
.whatsapp {
  margin-right: -11.5rem;
  transition: all 0.5s;
}


.whatsapp img {
  height:50px;
  transition: all 0.5s;
}
.whatsapp:hover {
  margin-right: 0rem;
}


.floating-button .btn:link,
.btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  /* padding: 15px 40px; */
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  /* position: absolute; */
}

.floating-button   .btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.floating-button .btn:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.floating-button .btn-white {
 
}

.floating-button  .btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.floating-button .btn-white::after {
  /* background-color: #fff; */
}

.floating-button .btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.floating-button .btn-animated {
  -webkit-animation: moveInBottom 5s ease-out;
          animation: moveInBottom 5s ease-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
}



@-webkit-keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}





@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
