	/*
  	Flaticon icon font: Flaticon
  	Creation date: 05/09/2019 04:15
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("https://www.17sucai.com/preview/2413762/2021-11-25/spa%20xiangyingshi%20mb/spa%E2%95%A7%E2%88%9E%E2%95%99%C2%AA%E2%95%A9%E2%95%9C%E2%94%80%C3%BA%E2%96%91%CF%83/fonts/flaticon/font/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("https://www.17sucai.com/preview/2413762/2021-11-25/spa%20xiangyingshi%20mb/spa%E2%95%A7%E2%88%9E%E2%95%99%C2%AA%E2%95%A9%E2%95%9C%E2%94%80%C3%BA%E2%96%91%CF%83/fonts/flaticon/font/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-candle:before { content: "\f100"; }
.flaticon-bathrobe:before { content: "\f101"; }
.flaticon-towel:before { content: "\f102"; }
.flaticon-hot-stones:before { content: "\f103"; }
.flaticon-oil:before { content: "\f104"; }
.flaticon-thalassotherapy:before { content: "\f105"; }